var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : _c("div", { attrs: { id: "body-content-area" } }, [
              _c("div", { attrs: { id: "pagelayout" } }, [
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column is-one-third" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "block" }, [
                      _c("div", { staticClass: "errors" }, [
                        _c(
                          "ul",
                          _vm._l(Object.keys(_vm.errors), function (error) {
                            return _c(
                              "li",
                              { key: error, staticStyle: { color: "red" } },
                              [_vm._v(" " + _vm._s(_vm.errors[error]) + " ")]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.handleSubmitLocal.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c("div", [
                            _c("div", { staticClass: "field" }, [
                              _vm._m(1),
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.input.name,
                                      expression: "input.name",
                                    },
                                  ],
                                  staticClass: "input",
                                  attrs: { type: "text", name: "name" },
                                  domProps: { value: _vm.input.name },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.input,
                                        "name",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "field" }, [
                              _vm._m(2),
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.input.displayName,
                                      expression: "input.displayName",
                                    },
                                  ],
                                  staticClass: "input",
                                  attrs: { type: "text", name: "code" },
                                  domProps: { value: _vm.input.displayName },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.input,
                                        "displayName",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "field" }, [
                              _vm._m(3),
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.input.url,
                                      expression: "input.url",
                                    },
                                  ],
                                  staticClass: "input",
                                  attrs: { type: "text", name: "description" },
                                  domProps: { value: _vm.input.url },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.input,
                                        "url",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "field" }, [
                              _vm._m(4),
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.input.linkedUrl,
                                      expression: "input.linkedUrl",
                                    },
                                  ],
                                  staticClass: "input",
                                  attrs: { type: "text", name: "description" },
                                  domProps: { value: _vm.input.linkedUrl },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.input,
                                        "linkedUrl",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "field" }, [
                              _vm._m(5),
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.input.fromEmail,
                                      expression: "input.fromEmail",
                                    },
                                  ],
                                  staticClass: "input",
                                  attrs: { type: "text", name: "FromEmail" },
                                  domProps: { value: _vm.input.fromEmail },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.input,
                                        "fromEmail",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "field" }, [
                              _vm._m(6),
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.input.emailDomain,
                                      expression: "input.emailDomain",
                                    },
                                  ],
                                  staticClass: "input",
                                  attrs: { type: "text", name: "EmailDomain" },
                                  domProps: { value: _vm.input.emailDomain },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.input,
                                        "emailDomain",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "field" }, [
                              _vm._m(7),
                              _c(
                                "div",
                                { staticClass: "control" },
                                [
                                  _c("kendo-datepicker", {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      id: "reportingStartDate",
                                      name: "reportingStartDate",
                                      required: "required",
                                      format: "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.input.chartReportingDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.input,
                                          "chartReportingDate",
                                          $$v
                                        )
                                      },
                                      expression: "input.chartReportingDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", [
                              _vm._m(8),
                              _c("div", { staticClass: "field" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false",
                                    },
                                  ],
                                  staticClass: "button is-accent",
                                  attrs: {
                                    id: "file",
                                    type: "file",
                                    name: "pic",
                                    accept: "image/*",
                                  },
                                  on: { change: _vm.onLogoChange },
                                }),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticStyle: { "padding-top": "20px" } },
                              [
                                _c("div", { staticClass: "field is-grouped" }, [
                                  _c(
                                    "div",
                                    { staticClass: "box has-background-light" },
                                    [
                                      _c("figure", { staticClass: "image" }, [
                                        _c(
                                          "object",
                                          {
                                            attrs: {
                                              data: _vm.input.logoUrl,
                                              type: "image/png",
                                            },
                                          },
                                          [_c("img", { attrs: { src: "" } })]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._m(9),
                                ]),
                              ]
                            ),
                          ]),
                          _c("div", { attrs: { id: "buttongroup" } }, [
                            _c("div", { staticClass: "field is-grouped" }, [
                              _c("div", { staticClass: "control" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "button is-accent has-text-white",
                                    attrs: {
                                      disabled: !_vm.$hasPermissions(
                                        _vm.clientSession,
                                        ["SITES"],
                                        2
                                      ),
                                      type: "submit",
                                    },
                                  },
                                  [_vm._v("Save")]
                                ),
                              ]),
                              _c("div", { staticClass: "control" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button is-light",
                                    on: {
                                      click: function ($event) {
                                        _vm.resetForm()
                                        _vm.$router.go(-1)
                                      },
                                    },
                                  },
                                  [_vm._v("Cancel")]
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "column" }),
                ]),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Site Properties"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Site Name "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Company Name "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Site URL "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Linked URL "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" From Email "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Email Domain "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "label is-size-7", attrs: { for: "reportingStartDate" } },
      [
        _vm._v(" Dashboard Reporting Date "),
        _c(
          "span",
          {
            staticClass: "has-text-warning",
            staticStyle: { "padding-left": "3px" },
          },
          [_vm._v("*")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Logo "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "padding-left": "20px",
          display: "flex",
          "flex-direction": "column",
        },
      },
      [
        _c("label", { staticClass: "label is-size-7 has-text-warning" }, [
          _vm._v("PNG image with max size of 180 x 75 pixels"),
        ]),
        _c("div", { staticStyle: { "margin-top": "2rem" } }, [
          _c(
            "label",
            { staticClass: "button is-default", attrs: { for: "file" } },
            [_vm._v("Replace Logo")]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }